import React, { MouseEvent } from 'react';
import { Box } from 'grommet';
import {
  OutsideBoxDisclaimer,
  DisclaimerQuestionText,
  DisclaimerInsideText,
  CancelButton,
  OkButton
} from '../paymentOptionsStyles/makePaymentOptionsStyles';
import { simpleGTMDataLayer } from '../../../util/GTMHelpers';
import Modal from '../../modal/Modal';

interface MakePaymentOptionsProps {
  openOneIncURL: Function;
  page: string;
  showSecondModal: boolean;
  closeSecondModal: () => void;
  children: any;
  alt?: boolean;
}

export default ({
  openOneIncURL,
  page,
  showSecondModal,
  closeSecondModal,
  children,
  alt
}: MakePaymentOptionsProps) => {
  const clickHandlerYes = () => {
    simpleGTMDataLayer({
      event: `click-${page}DisclaimerModalOkButton`
    });
    if (alt) {
      openOneIncURL();
    } else {
      openOneIncURL(true);
    }
    closeSecondModal();
  };

  const clickHandler = () => {
    simpleGTMDataLayer({
      event: `click-${page}DisclaimerModalCancelButton`
    });
    closeSecondModal();
  };

  return (
    <Box>
      <Modal
        showModal={showSecondModal}
        onEsc={closeSecondModal}
        onClickOutside={closeSecondModal}
      >
        <OutsideBoxDisclaimer
          onClick={(event: MouseEvent) => {
            event.stopPropagation();
          }}
        >
          <Box>
            <DisclaimerQuestionText>Disclaimer</DisclaimerQuestionText>
            <DisclaimerInsideText>
              I understand that by clicking &quot;OK&quot;, I will be
              transferred to One Inc to collect my payment information and a
              2.5% processing fee will apply to any payments made with my credit
               or debit card. One Inc is a third-party payment solutions provider who will
              make a payment to State Auto on behalf of the insured. Payments
              made by ACH/eCHECK are not assessed this fee.
            </DisclaimerInsideText>
            <CancelButton
              onClick={() => {
                clickHandler();
              }}
              data-test-id="one-inc-disclaimer-cancel"
            >
              CANCEL
            </CancelButton>

            <OkButton
              onClick={() => {
                clickHandlerYes();
              }}
            >
              OK
            </OkButton>
          </Box>
        </OutsideBoxDisclaimer>
      </Modal>
      {children}
    </Box>
  );
};
