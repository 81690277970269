import React, { MouseEvent, useState } from 'react';
import { Box } from 'grommet';
import {
  OutsideBox,
  QuestionText,
  InsideText,
  NoButton,
  YesButton,
} from '../paymentOptionsStyles/makePaymentOptionsStyles';
import { simpleGTMDataLayer } from '../../../util/GTMHelpers';
import { DisclaimerModalPL } from '.';

interface MakePaymentOptionsProps {
  openOneIncURL: Function;
  page: string;
  closeModal: () => void;
  useDisclaimerModal: Boolean;
  flagEnabled: Boolean;
}

export default ({
  openOneIncURL,
  page,
  closeModal,
  useDisclaimerModal,
  flagEnabled
}: MakePaymentOptionsProps) => {
  const [showPLModal, setShowPLModal] = useState(false);
  const [showQuestionModal, setShowQuestionModal] = useState(true);
  const [isMortgagee, setIsMortgagee] = useState(false);
  
  const closeModals = () => {
    setShowPLModal(false);
    closeModal();
  }

  const clickHandlerYes = () => {
    simpleGTMDataLayer({
      event: `click-${page}MakePaymentModalYesButton`
    });
      if(useDisclaimerModal && flagEnabled) {
        setShowQuestionModal(false);
        setIsMortgagee(true);
        setShowPLModal(true);
      }
      else {
        openOneIncURL(true);
        closeModal();
      }
    };
  const clickHandler = () => {
    simpleGTMDataLayer({
      event: `click-${page}MakePaymentModalNoButton`
    });
    if(useDisclaimerModal && flagEnabled) {
      setShowQuestionModal(false);
      setIsMortgagee(false);
      setShowPLModal(true);
    }
    else {
      openOneIncURL();
      closeModal();
    }
  };

if(showQuestionModal){
  return (
    <OutsideBox
      onClick={(event: MouseEvent) => {
        event.stopPropagation();
      }}
    >
      <Box>
        <QuestionText>Homeowner Payment</QuestionText>
        <InsideText>
          Is this payment for a New Home Purchase or Mortgagee Bill?
        </InsideText>
        <NoButton
          onClick={() => {
            clickHandler();
          }}
        >
          NO
        </NoButton>

        <YesButton
          onClick={() => {
            clickHandlerYes();
          }}
        >
          YES
        </YesButton>
      </Box>
    </OutsideBox>
  );
}

else if(showPLModal){
  if(isMortgagee) {
    return(
        <DisclaimerModalPL showModal={showPLModal} closeModal={closeModals} onContinue={() => {openOneIncURL(true)}} />
    );
  } else {
    return (
        <DisclaimerModalPL showModal={showPLModal} closeModal={closeModals} onContinue={() => {openOneIncURL()}} />
    );
  }
}
return <div />
};
