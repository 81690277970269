import { Box, Button, Text } from 'grommet';
import styled from 'styled-components';
import { colors } from '../../../themes/default.theme';

export const OutsideBox = styled(Box)`
  cursor: default;
`;

export const OutsideBoxDisclaimer = styled(Box)`
  display: flex;
  padding: 30px;
  flex-direction: column;
  width: 600px;
`;

export const OutsideBoxCL = styled(Box)`
  cursor: default;
  width: 415px;
  height: 320px;
`;

export const QuestionText = styled(Text)`
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  margin-top: 10%;
  margin-right: 10%;
  margin-left: 10%;
`;

export const DisclaimerQuestionText = styled(Text)`
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #041e41;
  margin-bottom: 28px;
`;

export const InsideText = styled(Text)`
  text-align: center;
  font-size: 16px;
  margin-top: 5%;
  margin-right: 10%;
  margin-left: 10%;
`;

export const DisclaimerInsideText = styled(Text)`
  font-size: 18px;
  line-height: 25px;
  color: #041e41;
  margin-bottom: 50px;
`;

export const NoButton = styled(Button)`
  text-align: center;
  padding: 17px 10px 17px 15px;
  font-size: 16px;
  font-weight: 700;
  margin-top: 5%;
  margin-right: 5%;
  margin-left: 5%;
  &:hover {
    cursor: pointer;
  }
  background-color: white;
  border: 2px solid ${colors.green1};
  color: ${colors.green1};
`;

export const YesButton = styled(Button)`
  text-align: center;
  padding: 17px 10px 17px 15px;
  font-size: 16px;
  font-weight: 700;
  margin-top: 5%;
  margin-bottom: 5%;
  margin-right: 5%;
  margin-left: 5%;
  &:hover {
    cursor: pointer;
  }
  background-color: ${colors.green1};
  border: 2px solid ${colors.green1};
  color: white;
`;

export const ModalButtonsWrap = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const CancelButton = styled(Button)`
  margin-right: 20px;
  padding: 12px 30px;
  font-size: 18px;
  line-height: 23px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: #000000;
`;

export const OkButton = styled(Button)`
  cursor: pointer;
  padding: 12px 30px;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  background: #65a100;
  border-radius: 3px;
  color: #ffffff;
`;

export const Backdrop = styled.div`
  width: 100vw;
  height: 100vh;
  z-index: 2;
`;

export const DisclaimerModal = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 650px;
  height: 300px;
  font-style: 'PT-Sans';
  display: flex;
  flex-direction: column;
  background-color: #f4f6fa;
  border-radius: 3px;
  z-index: 2;
`;

export const TextDiv = styled.div`
  margin-left: 30px;
  margin-right: 30px;
`;

export const ModalHeader = styled.div`
  font-weight: 700;
  font-size: 20px;
  text-align: left;
  margin-top: 25px;
  margin-bottom: 25px;
  color: #041e41;
`;

export const ModalText = styled.div`
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  margin-bottom: 15px;
  &,
  &:hover {
    color: #000000;
  }
`;

export const ButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  margin-top: 230px;
  margin-left: 400px;
`;

export const StyledCancelButton = styled.button`
  background: transparent;
  border: none;
  height: 35px;
  min-width: 120px;
  border-radius: 3px;
  font-size: 16px;
  font-style: 'PT-SANS';
  text-align: center;
  &:hover {
    cursor: pointer;
  }
`;

export const StyledOKButton = styled.button`
  background: transparent;
  border: none;
  height: 35px;
  min-width: 85px;
  border-radius: 3px;
  font-size: 16px;
  margin-left: 20px;
  font-style: 'PT-SANS';
  text-align: center;
  background-color: #65a100;
  color: #f4f6fa;
  &:hover {
    cursor: pointer;
  }
`;

export const MakePaymentButtonContainer = styled.div.attrs(
  (props: { changeToDisabledCursor: boolean }) => props
)`
  [type='button']:disabled {
    cursor: ${props => props?.changeToDisabledCursor && 'not-allowed'};
  }
`;
